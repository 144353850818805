body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'mabry-light-pro';
  src:
    url('../public/fonts/mabry-light-pro.woff2') format('woff2'),
    url('../public/fonts/mabry-light-pro.woff') format('woff'),
    url('../public/fonts/mabry-light-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mabry-bold-pro';
  src:
    url('../public/fonts/mabry-bold-pro.woff2') format('woff2'),
    url('../public/fonts/mabry-bold-pro.woff') format('woff'),
    url('../public/fonts/mabry-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mabry-regular-pro';
  src:
    url('../public/fonts/mabry-regular-pro.woff2') format('woff2'),
    url('../public/fonts/mabry-regular-pro.woff') format('woff'),
    url('../public/fonts/mabry-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'recife-display-light';
  src: url('../public/fonts/RecifeDisplay-Light.otf') format('woff2');

  font-weight: normal;
  font-style: normal;
}
